.options__panel {
  display: flex;
  padding: 0;
  justify-content: space-around;
}

.options {
  display: flex;
  width: 80px;
  justify-content: space-around;
}

.filter__button {
  border: 2px solid #303c50;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}

.filter__button .filter__icon {
  margin-right: 0 !important;
  font-size: 20px;
  color: #76ebb0;
}

.filter__button .search__icon {
  margin-right: 0 !important;
  font-size: 20px;
  color: #76ebb0;
}