@import "./../variables";

$primary_color : var(--primary-color);
$secoundary_color : var(--secoundary-color);

.btn.btn-primary {
  background-color: $secoundary_color;
  font-size: 12px;
}

button.btn.btn-cancel {
  box-shadow: 1px 1px 2px;
  width: 100px;
}

button.btn.btn-save {
  box-shadow: 1px 1px 2px;
  width: 112px;
  background-color: $secoundary_color;
  color: white;
}

.btn.btn-primary.generaldetails-button {
  background-color: #c4e4f0;
  border-radius: 16px;
  color: #28292c;
  border: none;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #28292c;
}

.c-icon.save-icon {
  width: 16px;
  padding-bottom: 4px;
  padding-right: 4px;
}

.attendance-mark {
  position: absolute;
  top: 30px;
}
.upload-button{
  position: absolute;
  margin-left: 80.3%;
  top: 4px;
  width: 11rem !important;

}

// upload button

.km-button--primary {
    background-color: #5C5AA7;
    color: grey;
}
.km-button {
    border-radius: 3px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    height: 36px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-out;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
}


.im-button {
    border-radius: 3px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: transparent;
    border: 1px solid grey;
    border-radius: 40px;
    width: 180px;
    height: 100px;
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    transition: all 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.im-btn-file-label {
    line-height: 36px;
    cursor: pointer;
    color: grey;
  }

.km-btn-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .km-btn-file-label {
    border-bottom: 1px solid #d9dce1;
    line-height: 36px;
    cursor: pointer;
    color: grey;
  }