@import "./../variables";

$primary_color : var(--primary-color);
$secoundary_color : var(--secoundary-color);

.modal-content {
  width: 30vw;
}

// close button

.closeButton {
  background-color: #bebebe;
  width: 20px;
  height: 20px;
  border-radius: 16px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
}

// modal header

.alert-header {
  border: none;
}

.alert-header.modal-header {
  position: relative;
  width: 100%;
}

// modal body

.message-content {
  text-align: center;
}

.message-content.modal-body {
  padding: 1.5rem 0;
  font-size: 16px;
  font-weight: 500;
}

// modal footer

.alert-footer {
  justify-content: center;
  border: none;
}

.alert-footer.modal-footer {
    padding: 0.5rem 0 1rem 0;
}

// footer button

.alert-btn {
  // background-color: #181e32;
  background-color: $primary_color;
  width: 100px;
  height: 40px;
  border-radius: 0rem;
  color: white;
}