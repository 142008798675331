@import "variables";

$primary_color : var(--primary-color);
$secoundary_color : var(--secoundary-color);
// Here you can add other styles
.search-bar {
  width: 250px;
  float: left;
}

.c-app.c-default-layout.flex-row.align-items-center {
  // background-image: url(https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://digiscorp.com/blog/wp-content/uploads/2019/10/HRMS.jpg);
  // background-size: cover;
}

.srv-validation-message {
  color: red;
}

.input-group-text {
  border: none;
  background: none;
  height: 9px;
  position: absolute;
  margin-left: -16px;
  margin-top: 6px;
}

.to_date {
  margin-top: 9px;
}


// to edit



// modified



// option panel



// bread crum

// span.c-header-toggler-icon {
//   display: none;
// }




.logo-margin{
    margin-top: -11px
}



.tab-pane.active.fade.show {
    margin-top: 14px;
}

.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #f9f9f987;
}

.spinner-border{
  justify-content: center;
  display: flex;
  margin-left: 50%;
  margin-top: 20%;
}

.set-jumbotron-title {
  position: absolute;
  top: 10px;
  font-weight: 500;
}


.job-post{
  margin-top: 2rem;
}

.checkbox_container .label-custom {
  margin-left: 6px;
  margin-top: 0px;
  padding: 1px;
}

.move-right{
  float:right;
}
.pointer{
  cursor: pointer;
}
.react-horizontal-scrolling-menu--wrapper {
  width:100%
}
::-webkit-scrollbar {
  height: 10px;              /* height of horizontal scrollbar ← You're missing this */
  // width: 4px;               /* width of vertical scrollbar */
  border: 1px solid #f3faff;
  background-color: #f3faff;
}
::-webkit-scrollbar-thumb {
  background: #4D148C;
  border-radius: 15px;
}
.leavecheckbox-cont .custom-control-label::before{
  width: 1.5rem;
  height: 1.5rem;
}
.leavecheckbox-cont .custom-control-label::after{
  width: 1.5rem;
    height: 1.5rem;
}
.leavecheckbox-cont .custom-control-label{
  padding-left: 10px;
  font-size: 20px;
  font-family: sans-serif;
}
.leavecheckbox-cont{
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.head-container{
  height: 5rem;
}
.capitilize{
  text-transform: capitalize;
}
.upperCase{
  text-transform: uppercase;
}
.selectEmployee{
  width: 400px;
}


// Custom style added by bhavesh k.
.card
{
  box-shadow: 0 1px 1px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 2px 1px -1px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 3px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2);
}

.c-sidebar {
  width: 235px !important;
}
.c-sidebar, .c-sidebar .c-sidebar-nav-dropdown.c-show {
  color: #252525 !important;
  background: #fff;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link:hover, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active {
  //color: #4F1887 !important
color : $primary_color
}
.c-sidebar .c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link {
  color: #252525;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-sidebar-minimizer:hover {
  background-color: #ffd8c7 !important;
  //color: #4F1887 !important
color : $primary_color;
  border-radius: 5px !important;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.active.breadcrumb-item {
  color: #252525 !important;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon:hover, .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon:hover,
.c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-link.c-active, .breadcrumb-item a, .c-footer a {
  //color: #4F1887 !important
color : $primary_color;
}

.c-sidebar .c-sidebar-brand, .c-sidebar .c-sidebar-minimizer {
  background: #fff;
}

.c-sidebar .c-sidebar-brand {
  margin-top: 10px;
}
.c-wrapper:not(.c-wrapper-fluid) .c-body, .c-app {
  background-color: #fcfcfc;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E")
}

.btn.btn-primary, button.btn.btn-save, .actions-button .btn.btn-info, .c-switch-3d-success .c-switch-input:checked+.c-switch-slider, .tableData .btn-success, .c-sidebar-nav-link .badge-info {
  background-color: $primary_color;
  border-color: $primary_color;
}

button.btn.btn-cancel {
  background-color: #ebedef !important;
  border-color: #ebedef !important;
}
.tableData .btn-success {
  padding: 0.15rem 0.5rem !important;
}
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #F5F5F5 !important;
}
.table-striped tbody tr:nth-of-type(2n) {
  background-color: #fff !important;
}

.page-item.active .page-link {
  background-color: $primary_color;
  border-color: $primary_color;
}

.filter__button .filter__icon, .filter__button .search__icon {
  color: $primary_color;
}
@media (min-width:768px)  {
  .container-fluid {
    padding-left: 0 !important;
  }
}

.card-header {
  margin-bottom: 0;
  // background: #4f1887;
  // background: linear-gradient(90deg,#4f1887,#9360f2);
  background: $primary_color;
  background: linear-gradient(90deg, $primary_color, $secoundary_color);
  border-bottom: 1px solid #fff;
  text-transform: uppercase;
  color: #fff !important;
}
.card-header h4, .card-header strong {
  font-size: 16px !important;
}
.react-bs-table-no-data {
  text-align: center !important;
    /* padding: 0.75rem; */
  vertical-align: top !important;
  border-top: 1px solid #c8ced3 !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #FF6600 !important;
  color: #fff !important;
  border-color: #ff5722 !important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
// a {
//   color: #4F1887 !important;
// }
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
}

// Login Screen Design
.login-card {
  padding: 75px 45px;
}
.login-container {
  margin: 80px 0 0 !important;
  border-radius: 15px;
  border: 1px solid #ececec;
}

.login-card .font-20 {
  font-size: 1.50rem;
  line-height: 1.22;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.login-card .mt-35 {
  margin-top: 35px !important;
}
.login-card .input-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.19;
  color: #171347;
}
.login-card .form-control {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #343434;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ececec;
  border-radius: 0.5rem !important;
  box-shadow: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.login-card .btn-primary {
  box-shadow: 0 3px 6px 0 rgb(64 213 125 / 30%);
  transition: all .3s;
  font-size: 16px !important;
  border-radius: 8px !important;
  height: 45px !important;
}
.login-card .mt-20, .login-card .my-20 {
  margin-top: 20px!important;
}

.login-card .mb-20, .login-card .my-20 {
  margin-bottom: 20px!important;
}

.login-card .text-center {
  text-align: center!important;
}

.login-card .btn-group-sm>.btn, .login-card .btn-sm {
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.login-card .rounded-pill {
  border-radius: 50rem!important;
}

.login-card .btn-group-sm>.btn, .login-card .btn-sm {
  padding-right: 16px;
  padding-left: 16px;
  height: 36px;
  font-size: 1rem;
  font-weight: 400;
}

.login-card .btn-group-lg>.btn, .login-card .btn-group-sm>.btn, .login-card .btn-lg, .login-card .btn-sm {
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.login-card .btn-outline-secondary {
  // color: #1f3b64;
  // border-color: #1f3b64;
  color: $secoundary_color;
  border-color: $secoundary_color;
}
.img-cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
}
.login-container img {
  border-radius: 15px 0 0 15px !important;
}
.login-card .mt-30 {
  margin-top: 30px !important;
}
.login-card .font-login-as {
  font-size: 1rem;
}
@media (min-width: 1400px) {
  .login-screen .container {
    max-width: 1140px;
  }
}
.sticky-nav {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  border-bottom: 1px solid #ececec !important;
}

.bg-gradient-primary {
  // background: linear-gradient(45deg, #321fdb 0%, #1f1498 100%) !important;
  // border-color: #1f1498 !important;
  background: linear-gradient(45deg, $primary_color 0%, $secoundary_color 100%) !important;
  border-color: $secoundary_color !important;
}
.bg-gradient-primary .text-white {
  color: #000 !important;
}
.modal-header {
  background-color: $primary_color;
  color: #fff !important;
}
.modal-header button.close, .modal-header button.close:hover {
  color: #fff;
}

.earning-dedcution-parent .jumbotron {
  border: 1px solid #f2f2f2 !important;
  background: #fff !important;
  padding: 1rem 1rem !important;
  margin-bottom: 1rem !important;
}
.borderb2 {
  vertical-align: bottom;
  border-bottom: 2px solid #d8dbe0;
}
.p-v-m {
  padding-top: 12px;
  padding-bottom: 12px;
}
.m-v-s {
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 600;
}

body::-webkit-scrollbar {
  width: 10px;
}
 
body::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #4f1887, #9360f2);
  border-radius: 20px;
}

body::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #f3faff!important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3faff!important;
}


// custom scroll css

.outer-wrapper {
  max-width: 100vw;
  overflow-x: scroll;
  position: relative;
  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.pseduo-track {
  background-color: #f1db9d;
  height: 2px;
  width: 100%;
  position: relative;
  top: -3px;
  z-index: -10;
}

@media (any-hover: none) {
  .pseduo-track {
    display: none;
  }
}

.outer-wrapper::-webkit-scrollbar {
  height: 5px;
}

.outer-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.outer-wrapper::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #d5ac68;
}

.outer-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #f1db9d;
}

.outer-wrapper::-webkit-scrollbar:vertical {
  display: none;
}

.inner-wrapper {
  padding-bottom: 10px;
}