@import "./../variables";

$primary_color : var(--primary-color);
$secoundary_color : var(--secoundary-color);

.table-head-section {
  float: right;
}

.tableData{
    padding: inherit;
    padding-left: 0;
}

.table.table-striped.table-bordered {
  // background-color: #ffffff;
  // border: none;
}

.table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3faff!important;
}


.table.table-striped.table-bordered th {
  background-color: #ffffff;
  background-color: #f0f4fb;
  color: #363636;
  border: none;
  font-weight: 700;
}

.table.table-striped.table-bordered td {
  background-color: #ffffff;
  color: #686868;
  border: none;
  font-weight: 500;
  padding-top: 16px;
}


// table checkbox button

.table.table-striped.table-bordered input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

.table.table-striped.table-bordered input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #e9e9e9;
}
.table.table-striped.table-bordered input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  //background-color: #1e80ef;
  background-color: $primary_color;
}
.table.table-striped.table-bordered input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 4px;
  left: 8px;
}

// table switch button

td .c-switch {
  padding: 0;
}
.c-switch.form-check-label.c-switch-3d-success.mx-1 {
}

.c-switch-input.c-form-check-input {
  display: none;
}

span.c-switch-slider {
  width: 32px;
  height: 16px;
}

.c-switch[class*="-3d"] .c-switch-slider::before {
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 50em;
  box-shadow: 0 2px 5px rgb(0 0 21 / 30%);
}

// table action buttons

.actions-button {
  display: flex;
  width: 72px;
  justify-content: space-between;
  position: relative;
  top: -10px;
}

.actions-button .btn {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 14px;
}

.actions-button .btn.btn-info  {
    //background-color: #303c50;
    background-color: $primary_color;
}
