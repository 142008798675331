@import "variables";

$primary_color : var(--primary-color);
$secoundary_color : var(--secoundary-color);
.switch_btn span.c-switch-slider {
    width: 40px;
    height: 27px;
}
.salary_table{
    background-color: #fff;
}
.inPut_Lables{
   font-size: 12px;
    height: 25px;
}
.capital {
    text-transform: uppercase;
}

.addbtncenter{
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
}
.addbtncenter:hover {
    //color: #321fdb;
    color : $primary_color
}
.punchContainer{
    width: 100%;
    margin: auto;
    left: 35%;
    top: 10%;
    position: absolute;
}
h3.headfont{
    font-weight: bold;
}
.mixed-chart {
    justify-content: center;
    // display: grid;
    // justify-content: space-around;
    padding: 0 0.1rem;
    margin: 0.2rem 1rem;
}
