.input__lable_icon {
  padding-right: 4px;
}

.label-custom {
  font-size: 13px;
  font-weight: bold;
}

.inline-flag-container {
  border-bottom: 1px solid #d9dce1;
}

.inline-flag-container p {
  display: inline-block;
  font-size: 12px;
}

.custom-group {
  position: absolute;
  margin-left: 90% !important;
  margin-top: 4%;
  color: grey;
}

.form-control:focus {
  box-shadow: none !important;
}

.logo-margin {
  margin-top: -11px;
}

.input-group-text {
  border: none;
  background: none;
  height: 9px;
  position: absolute;
  margin-left: -16px;
  margin-top: 6px;
}

.form-control:focus {
  box-shadow: none !important;
}

.custom_input {
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 0;
  background: none !important;
}

.jumbotron {
  background: #f2f2f2 !important;
}

.profile_image {
  width: 180px;
  height: 100%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    border: 1px solid black;
    object-fit: fill;
    border-radius: 33px;
  }
}



i.fa.fa-calendar {
  position: absolute;
  top: 2.5rem;
  margin-left: 18.5rem;
}


i.fa.fa-clock-o {
  position: absolute;
  top: 2.5rem;
  margin-left: 18.5rem;
}


.custom_input.text_area {
  border: 1px solid #d9dce1;
  height: 150px;
  padding: 5px;
}

.form-group.moveBtn {
  margin-top: 10%;
}