// breadcrum
@import "./../variables";

$primary_color : var(--primary-color);
$secoundary_color : var(--secoundary-color);

.breadcrumb-item {
  //color: #303c50 !important;
  color: $primary_color;
  font-weight: bold;
}

.active.breadcrumb-item {
  //color: #303c50;
  color: $primary_color;
  font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
   //color: #303c50;
   color: $primary_color;
  font-weight: bold;
  content: ">";
}

// search
.custom-group {
  position: absolute;
  margin-left: 90% !important;
  margin-top: 4%;
  color: grey;
}

.search-bar {
  width: 250px;
  float: left;
}


.logo-margin {
  margin-top: -11px;
}

// emloyee nav

.custom_nav .nav-item {
  border-left: 1px solid grey;
  border-bottom: 2px solid grey;
  min-width: 80px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.custom_nav  .active.nav-link.tab-nav-link{
  border-radius: 0rem;
  //background-color: #303c50;
  background-color: $primary_color;
}

.custom_nav_recruitment .nav-item {
  min-width: 80px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: gray;
}

.custom_nav_recruitment  .active.nav-link.tab-nav-link{
  border-radius: 0rem;
  background-color: lightgrey;
  border-bottom: 2px solid grey;
  // color: #303c50;
  color: $primary_color;
}

.custom_nav_recruitment .nav-link.tab-nav-link{
  color: grey;
}